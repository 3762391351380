import { Component } from 'preact';
import { Page, Header, Content } from '@desicode/components';
import { Link } from 'preact-router';

export class NotFound extends Component {
  render() {
    return (
      <Page>
        <Header title="Page not found" />
        <Content>
          <section class="box">
            Could not find this page, please try navigating back to <Link href="/">home</Link>
          </section>
        </Content>
      </Page>
    );
  }
}
