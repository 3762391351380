import { h, render } from 'preact';
import App from './components/app';

// Enable debug mode. You can reduce the size of your app by only including this
// module in development builds. eg. In Webpack, wrap this with an `if (module.hot) {...}`
// check.
// import 'preact/debug';
import './style/styles.css';
import './style/index.css';

window.h = h;
const app = document.getElementById('app');
render(h (App, null), app);
