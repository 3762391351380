import { Component } from 'preact';
import { Page, Header, Content } from "@desicode/components";
import { Link } from "preact-router";
import { AppStore } from "../lib/store";
import http from "@desicode/fetch";
import CONSTANTS from '../lib/constants';

export default class Dashboard extends Component {
  render ({}, { }) {
    return (
      <Page>
        <Header classes="desifont has-text-center" title="About Us" />
        <Content>
          <div class="box">
						<h3 class="desifont has-text-center">Unique DNA</h3>
            <p>
              Being a <span class="desifont">DESI</span> company, we are extremely wary of efficiency in our process(es).
            </p>
            <p>
              Some of the factors that distinguish us from all our competitors are,
            </p>
            <ul>
              <li>
                Minimal costs
              </li>
              <li>
                Use of bleeding-edge technology
              </li>
              <li>
                Round-the-cloud support with the help of Humans & ChatBots
              </li>
              <li>
                Customer obsessed
              </li>
              <li>
                Agile
              </li>
            </ul>
            <h3 class="desifont has-text-center">The Team</h3>
            <p>
              A team of Gujju siblings set out to change how computing and cloud business is done in the subcontitent.
            </p>
            <ul>
              <li>
                Dhruvi
              </li>
              <li>
                Vidhi
              </li>
              <li>
                Pratik
              </li>
              <li>
                Kaushal
              </li>
            </ul>
          </div>
        </Content>
      </Page>
    );
  }
}
