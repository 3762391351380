import { Component } from 'preact';
import { Router, route } from 'preact-router';
import { Match } from 'preact-router/match';
import { AppStore } from '../lib/store';
import { NotFound } from './errorpages/notfound';
import { UserHeader } from './user.header';
import About from '../routes/about';
import Dashboard from '../routes/dashboard';

import http from '@desicode/fetch';

const HTTP_UNAUTHORIZED_CODE = 401;
http.responseInterceptor = async (response) => {
  // console.log('Intercepting response', response.headers.get('content-type'));
  if (response.statusCode === HTTP_UNAUTHORIZED_CODE) {
    route(`/login`);
  }
};

http.requestInterceptor = () => {
  const headers = {
    Authorization: AppStore.get('token').token,
    'Content-Type': 'application/json'
  };
  return new Headers(headers);
};

export default class App extends Component {
  render() {
    const nonHeaderURLs = ['/login', '/admin/login'];
    return (
      <Match path="/">
        {
          ({path}) => {
            let shell = false;
            if (nonHeaderURLs.indexOf(path) < 0) {
              shell = true;
            }
            return (
              <div class={shell ? "shell" : ""}>
                <Match path="/">
                  {
                    ({path}) => {
                      if (shell) {
                        return (<UserHeader/>);
                      }
                    }
                  }
                </Match>
                <div class="page">
                  <Router>
                    <Dashboard path="/"/>
                    <About path="/about"/>
                    <NotFound path="*" />
                  </Router>
                </div>
              </div>
            );
          }
        }
      </Match>
    );
  }
}
