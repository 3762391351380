import { h, Component } from 'preact';
import { Link } from 'preact-router';
import CONSTANTS from '../lib/constants';
import { AppStore } from '../lib/store';
import http from '@desicode/fetch';

export class UserHeader extends Component {

  componentWillMount() {
    this.setState({
      brand: '|01| DesiTech',
      username: '',
      email:''
    });
  }

  toggleMenu(evt) {
    evt.target.parentNode.classList.toggle('open');
  }
  render({}, { brand, username }) {
    return (
      <aside class="menu">
        <span class="menu-toggle" onClick={this.toggleMenu.bind(this)}>&#9776;</span>
        <ul class="content">
          <li>
            <Link activeClassName="active" path="/about" href="/about">About Us</Link>
          </li>
          {
            username.length > 1 && (<li>
              <Link activeClassName="active" href="/profile">Hi {username}</Link>
            </li>)
          }
          <hr/>
          <li>
            <h4>
              <Link class="desifont" path="/" href="/">{brand}</Link>
            </h4>
          </li>
        </ul>
      </aside>
    );
  }
}
