import { Component } from 'preact';
import { Page, Header, Content } from "@desicode/components";
import { Link } from "preact-router";
import { AppStore } from "../lib/store";
import http from "@desicode/fetch";
import CONSTANTS from '../lib/constants';

export default class Dashboard extends Component {
  render ({}, { }) {
    return (
      <Page>
        <Header classes="desifont has-text-center" title="desitech" />
        <Content>
          <div class="box has-text-center">
						<h3 class="desifont">Solutions</h3>
						<p>
							<ul>
								<li>
									Digital transformation
								</li>
								<li>
									Document collaboration
								</li>
								<li>
									Cloud hosting and Managed services
								</li>
								<li>
									Cross-platform development consulting
								</li>
								<li>
									Database migration
								</li>
								<li>
									Open Source software consulting
								</li>
							</ul>
						</p>
					</div>
					<div class="box has-text-center">
						<h3 class="desifont">Technologies</h3>
						<p>
							<ul>
								<li>
									IoT & Industrial-IoT
								</li>
								<li>
									BigData platforms and toolkits
								</li>
								<li>
									Continuous Integration and Continuous Deployment (CI/CD)
								</li>
								<li>
									Code collaboration - GitLab, GitHub, Gitea, Phabricator
								</li>
								<li>
									Popular SQL and NoSQL technologies
								</li>
								<li>
									NodeJS, Java, PHP, Golang, Rust
								</li>
							</ul>
						</p>
					</div>
					<div class="box has-text-center">
						<h3 class="desifont">Industries</h3>
						<p>
							<ul>
								<li>
									Banking & Finance
								</li>
								<li>
									Education & eLearning
								</li>
								<li>
									Real Estate & Housing
								</li>
								<li>
									Food & Restaurant
								</li>
								<li>
									Media & Entertainment
								</li>
							</ul>
						</p>
          </div>
        </Content>
      </Page>
    );
  }
}
